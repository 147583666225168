import React from 'react'
import styled from 'styled-components'
import Layout from '../components/layout'
import HeaderSubsite from '../components/headersubsite'
import Footer from '../components/footer'
import SubFooter from '../components/subfooter'

import Service from '../components/serviceblog/service'
import ImageText from '../components/imagetext'
import ConsultingAppointment from '../components/consultingappointment'

import Srd2 from '../images/steuerberatung-srd2.jpg'

import Welcome from '../images/welcome.jpg'

const ServicePacked = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`

const BranchenPage = () => (
  <Layout>
    <HeaderSubsite
      headline="Branchen"
      subline="Ganzheitlich für Firmen aller steuerlicher Belange"
      bg={Srd2}
    />
    <ImageText
      bg={Welcome}
      subline="Weil Mittelstand sich "
      headline=" kein Mittelmaß leisten kann."
    >
      <p>
        <br />
        Wer als Kanzlei den Mittelstand mit seinen spezifischen steuerlichen und
        betriebswirtschaftlichen Anforderungen beraten und auch vor Behörden und
        Gerichten vertreten will, der muss ihn verstehen.
        <br />
        <br />
        Als Steuerberater mit profunden Kenntnissen in den verschiedensten
        Wirtschafts- und Industriezweigen haben wir uns in den vergangenen 25
        Jahren einen erstklassigen Ruf bei zahlreichen national und
        international operierenden mittelständischen Unternehmen erarbeitet.
        Digitale Transformation, Industrie 4.0, IOT, Innovation vs. Disruption –
        das sind für uns keine abstrakten Fremdwörter, sondern ein Business, mit
        dem wir bestens vertraut sind.
        <br />
        <br />
        Selbstverständlich nehmen wir als echte Mittelstandsversteher Ihre
        steuerlichen Interessen auch dann wahr, wenn Ihr Unternehmen sich
        außerhalb der Metropolregion München befindet. Unsere Berater sind mit
        den rechtlichen Richtlinien und Besonderheiten der verschiedensten
        Wirtschafts- und Industriezweigen bestens vertraut und unterstützen Sie
        aktiv und ganzheitlich in allen steuerlichen Belangen.
        <br />
      </p>
    </ImageText>
    <ServicePacked>
      <Service title="STEUERERBERATUNG" bg="#d3a04e">
        <ul>
          <li>Land- und Forstwirtschaft</li>
          <li>Bau und Immobilien</li>
          <li>Handel und Konsumgüter </li>
          <li>IT und Software</li>
          <li>Energiewirtschaft</li>
          <li>Family Office</li>
          <li>Finanzdienstleistungen</li>
        </ul>
      </Service>
      <Service title="STEUERERBERATUNG" bg="#d6ae62">
        <ul>
          <li>Gesundheitswesen</li>
          <li>Industrielle Produktion</li>
          <li>Medien und Verlagswesen</li>
          <li>Non-Profits</li>
          <li>Private Equity</li>
          <li>Touristik</li>
          <li>Gastronomie</li>
        </ul>
      </Service>
    </ServicePacked>

    <ConsultingAppointment
      title={
        <>
          {`Nehmen Sie Kontakt mit uns auf. Wir sind davon überzeugt,`}
          <br />
          {` dass wir die gleiche Sprache sprechen.`}
        </>
      }
      cta="Termin vereinbaren"
      bgcolor="#fff"
      color="#333"
      bgctacolor="#ccc"
    />

    <Footer />
    <SubFooter />
  </Layout>
)

export default BranchenPage
