import React from 'react'
import styled from 'styled-components'
import SuperHeadline from '../superheadline'
import MegaCopytext from '../megacopytext'

const ServiceWrapper = styled.div`
  padding: 50px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  width: 50%;
  background-color: ${props => props.bg};
  @media only screen and (max-width: 1000px) {
    width: 100%;
    padding: 20px;
  }
`

export default function Service(props) {
  const { title, children } = props
  return (
    <ServiceWrapper bg={props.bg}>
      <SuperHeadline>{title}</SuperHeadline>
      <MegaCopytext>{children}</MegaCopytext>
    </ServiceWrapper>
  )
}
